<template>
    <div>
        <Header></Header>
        <div class="main-content">
            <div class="left-box">
                <el-menu :default-active="active" router
                    class="el-menu-vertical-demo">
                    
                    <el-menu-item index="/profile">
                        <i class="iconfont icon-yonghutianchong mr15"></i>
                        <span slot="title" >个人中心</span>
                    </el-menu-item>
                    <el-submenu index="1">
                        <template slot="title">
                            <i class="iconfont icon-shipin1 mr15"></i>
                            <span slot="title">我的课程</span>
                        </template>
                        <el-menu-item index="/view_record">观看记录</el-menu-item>
                        <el-menu-item index="/record">购买记录</el-menu-item>
                        <el-menu-item index="/collection">课程收藏</el-menu-item>
                        <el-menu-item index="/myComments">课程评论</el-menu-item>
                    </el-submenu>
                    
                </el-menu>
            </div>
            <div class="right-box">
                <router-view ></router-view>
            </div>
        </div>
        <gap height="50"></gap>
            <Homefooter ></Homefooter>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                active:'',
            }
        },
        created(){
            this.active = this.$route.path;
        },
        methods:{

        }
    }
</script>

<style lang="less" scoped>
.main-content {
    width: 1200px;
    margin: 20px auto;
    display: flex;
}
.left-box {
    background-color: #fff;
    width: 190px;
    padding: 10px 0;
    min-height: 600px;
    margin-right: 15px;
    overflow: hidden;
    .el-menu {
        border-right: 0;
    }
}
.right-box {
    flex:1;
    position: sticky;
    top: 50px;
    min-height: 600px;
    max-height: 800px;
    // background-color: #fff;
}
</style>